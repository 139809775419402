@if (promotion) {
<div #promoCard class="theme-{{siteID}} relative flex flex-col h-full rounded-sm shadow-xl bg-off-white w-[20rem]">
  <div class="relative grow">
    <div class="relative w-full h-36 overflow-hidden rounded-t-sm">
      <img width='320' height='144' src="{{ promotion.Photo.Image | imgix:'fit=crop':'h=250':'q=10' }}" alt="{{ promotion.Photo.Alt }}" class="w-full h-full object-cover rounded-t-sm" />
      @if((siteID === 'Alaska' || siteID === 'CI') && promotionVendor && promotionVendor.LogoWhite.length > 0) {
        <div class="absolute right-0 top-0 p-4">
          <div class="radial-grad-bg absolute -top-3/4 -bottom-1/2 -left-1/2 -right-3/4"></div>
          <img [src]="promotionVendor.LogoWhite | imgix:'fit=crop':'w=165'" sizes="165px" alt="{{ promotionVendor.Name }} Logo" class="relative w-full max-w-[165px] max-h-[50px]" />
        </div>
      }
    </div>
    <div class="min-h-[260px]">
      <div class="p-4 bg-off-white gap-2 flex flex-col">
        <h2
          class="opt-out font-bold uppercase tracking-wider leading-tight theme-Azamara:font-azamaraSerif theme-Oceania:font-oceaniaSerif theme-Regent:font-regentSerif theme-Silversea:font-silverseaSerif theme-Windstar:font-windstarSerif">
          @if (promotion.PromoBuckets.length > 1) {
            <!-- promotion-detail-buckets -->
            <a routerLink="/promotions/buckets/{{promotion.Title | toSlug}}/{{promotion.ID}}" class="opt-out">{{promotion.BriefTitle}}</a>
          } @else {
            <a routerLink="/promotions/{{promotion.Title | toSlug}}/{{promotion.ID}}" [queryParams]="routeQueryParams" class="opt-out">{{promotion.BriefTitle}}</a>
          }
        </h2>
        <div class="text-sm font-bold leading-tight flex flex-col gap-2">
          @if (compareDates(promotion.Expiration)) {
          <div class="flex items-start text-gray-400">
            <i class="mr-1">
              <icon-calendar></icon-calendar>
            </i>
            <span class="text-sm italic">
              Book by {{promotion.Expiration | date}}
            </span>
          </div>
          }
          @if (promotion.Category) {
          <div class="flex items-start text-tertiary-300">
            <i class="mr-1">
              <icon-promotion-category [icon]="promotion.CategoryIcon" [fill]="'text-tertiary-300'"></icon-promotion-category>
            </i>
            <span class="text-sm">{{promotion.Category}}</span>
          </div>
          }
        </div>
        <div [innerHTML]="promotion.Subtitle" class="flex flex-col flex-grow min-h-[4.5rem]"></div>
      </div>
    </div>
  </div>
  <div class="px-4 py-5 flex flex-row justify-between bottom rounded-b-sm">
    @defer (on viewport(promoCard)) {
      <app-promotion-price [promotion]="promotion"></app-promotion-price>
    }
    @if (promotion.PromoBuckets.length > 1) {
      <a routerLink="/promotions/buckets/{{promotion.Title | toSlug}}/{{promotion.ID}}"
      class="btn block text-center">View Sailings</a>
    } @else {
      <a routerLink="/promotions/{{promotion.Title | toSlug}}/{{promotion.ID}}" [queryParams]="routeQueryParams" class="btn block text-center">View Sailings</a>
    }
  </div>
</div>
}